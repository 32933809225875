import { Component, Input, OnInit } from '@angular/core';
import { HEADERMENULINKS, statusClass } from 'src/app/model/constants';
import { mapOrder } from 'src/app/utils';
import { IDownloadApp } from '../download-app/api/download.interface';
import { FooterApiService } from './api/footer.api.service';
import { IFooterLink } from './api/footer.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private footerApiService: FooterApiService) { }

  exploreList: IFooterLink[];
  @Input() dwnapp: IDownloadApp;

  ngOnInit(): void {
    this.getFooterLinks();
    this.getAppPlaceHolder();
  }

  getFooterLinks(): void {
    const list = [ 'pooja', 'ropeways', 'about' ];
    this.exploreList = HEADERMENULINKS.filter(a => list.includes(a.code));
    // console.log(this.exploreList)
    // this.footerApiService.getFooterLink().subscribe(response => {
    //   if (response.code === statusClass.overall_success_response){
    //     if (response.data && response.data.explore){
    //       const explorelink = HEADERMENULINKS.filter(a => response.data.explore.includes(a.code));
    //       this.exploreList = mapOrder(explorelink, response.data.explore, 'code');

    //     }
    //   }
    // }, (error: any) => {
    //   console.log('error on footer');
    //   // const list = [ 'pooja', 'astrology', 'ropeways', 'about' ];
    //   const list = [ 'pooja', 'ropeways', 'about' ];
    //   this.exploreList = HEADERMENULINKS.filter(a => list.includes(a.code));
    // });
  }

  async getAppPlaceHolder(): Promise<void> {
    const appPlaceHolder  = await this.footerApiService.getAppPlaceHolder().toPromise();
    if (appPlaceHolder.code === statusClass.overall_success_response) {
      this.dwnapp = appPlaceHolder.data;
     // console.log(this.dwnapp );
    }
  }
  redirectToUdhanKhatola(){
    let url="https://udankhatola.com";
    window.open(url, "_blank");
  }

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGULAGES } from './model/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'shaktipeeth';
  
  constructor(public translate: TranslateService){

    const language: string[] = LANGULAGES.map((data: any) => data.code);
    translate.addLangs(language);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|hn/) ? browserLang : 'en');
  }

  ngOnInit(): void{
    if (localStorage.getItem('lang') != null){
      const language: any =  JSON.parse(localStorage.getItem('lang'));
      if (language != null){
        this.translate.use(language.code ? language.code : 'en');
      }
    }
    if (localStorage.getItem('langParam') != null){
      // avoid to setItem
      // localStorage.setItem('langParam', '1');
    }else{
      localStorage.setItem('langParam', '1');
    }
  }
}



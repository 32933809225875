import { Component, OnInit, ChangeDetectionStrategy, Input  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { statusClass } from 'src/app/model/constants';
import { PolicyApiService } from '../api/policy.api.service';


@Component({
  // selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

   constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policyApiService: PolicyApiService
  ) {

  }

  policyData: any;
  ngOnInit(): void {
    this.policyApiService.getPolicy().subscribe(res => {
      console.log(res);
      if (res.code === statusClass.overall_success_response){
        this.policyData = res.data;
      }
    }, (error: any) => {

    });
  }

}

import { NgModule, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LANGULAGES } from './model/constants';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): any{
   return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    imports: [
      HttpClientModule,
      CommonModule,
      TranslateModule.forRoot({
         loader: {
           provide: TranslateLoader,
           useFactory: HttpLoaderFactory,
           deps: [HttpClient]
         },
         isolate: false
       })
    ],
    exports: [
      CommonModule,
      TranslateModule
    ]
})
export class SharedModule {
  constructor(private translate: TranslateService) {
  //  const language: string[] = LANGULAGES.map((data: any) => data.code);
  //  translate.addLangs(language);
  //  translate.setDefaultLang('en');

  //  const browserLang = translate.getBrowserLang();
  //  translate.use(browserLang.match(/en|hn/) ? browserLang : 'en');


  //  if (localStorage.getItem('lang') != null){
  //     const languagetwo: any =  JSON.parse(localStorage.getItem('lang'));
  //     if (languagetwo != null){
  //       this.translate.use(languagetwo.code ? languagetwo.code : 'en');
  //     }
  //   }
  //  if (localStorage.getItem('langParam') != null){
  //     // avoid to setItem
  //     // localStorage.setItem('langParam', '1');
  //   }else{
  //     localStorage.setItem('langParam', '1');
  //   }
  }


  // ngOnInit(): void {
   
  // }
}

export function parseUrl(url: string): URL {
    try {
      return new URL(url);
    } catch (err) {
      console.error(`Unable to parse the url, error: ${err}`);
    }
  }

export function timeFormat(time: any): string {
    // Hours, minutes and seconds
    // tslint:disable-next-line:no-bitwise
    const hrs = ~~(time / 3600);
    // tslint:disable-next-line:no-bitwise
    const mins = ~~((time % 3600) / 60);
    // tslint:disable-next-line:no-bitwise
    const secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';
    if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
}

// tslint:disable-next-line:typedef
export function mapOrder(array: any, order: any, key: string) {
    try {
    array.sort((a: any , b: any) => {
        // tslint:disable-next-line:one-variable-per-declaration
        const A = a[key], B = b[key];
        if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
        } else {
        return -1;
        }
    });
    return array;
    } catch (err) {
        console.error(`Unable to parse the url, error: ${err}`);
    }
}

export function randomInRange(min: number, max: number): any {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function gstAmount(baseprice: any, gst: any): string {
  if (baseprice != null && gst != null) {
    const price = parseFloat(baseprice);
    if (gst.includes('%')) {
      const amount = gst.split('%');
      return (price * (parseFloat(amount[0]) / 100)).toString();
    }
  }
}

export function DataURIToBlob(dataURI: string): Blob {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export function getUtcOffset(timeZone: string): any {
  const timeZoneName = Intl.DateTimeFormat('ia', {
    timeZoneName: 'short',
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === 'timeZoneName').value;
  const offset = timeZoneName.slice(3);
  if (!offset){
    return 0;
  }

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData){ throw new Error(`cannot parse timezone name: ${timeZoneName}`); }

  const [, sign, hour, minute] = matchData;
  // tslint:disable-next-line:radix
  let result = parseInt(hour) * 60;
  if (sign === '-'){
    result *= -1;
  }
  // tslint:disable-next-line:no-unused-expression
  if (minute) { result + parseInt(minute, 10); }

  return result;
}

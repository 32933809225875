import { Injectable} from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/model/constants';
import { IHeaderLinkResponse } from './menu.interface';

@Injectable()
export class MenuApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  // tslint:disable-next-line:no-inferrable-types
  private url = environment.baseUrl;

  public getHeaderLink(): Observable<IHeaderLinkResponse> {
    return this.http.get<IHeaderLinkResponse>(`${this.url}/getHeaderContent`);
  }

  public logout(): Observable<IHeaderLinkResponse> {
    return this.http.get<IHeaderLinkResponse>(`${this.url}/logout`);
  }

}

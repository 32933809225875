import { IMenu } from './common';

export const environment = {
    // baseUrl: 'https://shaktipeeth-dev.ushamartintech.com:8085/MockAPIGenerator/webapp'
     baseUrl: 'https://www.shaktipeethdigital.com/webservice/public/api/web',
    // 'https://shaktipeeth-dev.ushamartintech.com/production/public/api/web',
     //  baseUrl: 'https://shaktipeeth-dev.ushamartintech.com/shaktipeethbeta/public/api/web',
    // baseUrl: 'https://shaktipeeth-dev.ushamartintech.com/shaktipeethuat/public/api/web'


      // Razorpay pament getway Key
      // razorpay_key:  'rzp_live_6my8tkPML9LxTX',
      // razorpay_secret_key:  'bRgYUT6mEiWSdIQIDzcXfwo5',

      // Razorpay pament getway test Key
      razorpay_key:  'rzp_test_yLzqxWLttKfDSu',
      razorpay_secret_key:  '',

      // social login Google Client Id
      gClienId: '931114275064-45ndu3lv71ifnv2hur833d92n1l8bvuj.apps.googleusercontent.com'
      // '1047149553874-v59rmq0abai8fsp7irpv5jjfdoo9f07q.apps.googleusercontent.com'
};

export const statusClass = {
    overall_success_response: 6000,

    customer_has_and_notverify: 6001,
    customer_valid_and_verify: 6002,

    new_registration_otp_send: 6001,
    user_exists_otp_send: 6002,
    phoneno_requied_for_registration: 6003,

    transaction_type_wallet: 1,
    transaction_type_purchased: 0,
};

export const LANGULAGES: any[] = [
    {
      name: 'English',
      code: 'en',
      displayName: 'en',
      id: 1
    },
    {
      name: 'हिन्दी',
      code: 'hn',
      displayName: 'हिन्दी',
      id: 2
    },
    {
      name: 'ગુજરાતી',
      code: 'gj',
      displayName: 'ગુજરાતી',
      id: 3
    }
];
export const HEADERMENULINKS: IMenu[] = [
    {
      name: 'HEADER.POOJA',
      url: 'pooja',
      code: 'pooja'
    },
    {
      name: 'HEADER.ASTROLOGY',
      url: 'astrology',
      code: 'astrology'
    },
    {
      name: 'HEADER.ROPEWAYS',
      url: 'ropeways',
      code: 'ropeways'
    },
    {
      name: 'HEADER.OURSERVICES',
      url: 'ourservices',
      code: 'ourservices'
    },
    {
      name: 'HEADER.ABOUT',
      url: 'about',
      code: 'about'
    }
];

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';


import { environment, statusClass } from '../model/constants';
import { LoginBroadcast, User, UserWallet } from '../model/user';
import { randomInRange } from '../utils';
import { CompleteBookModel, CompleteWalletModel, IServerCommonResponse, PaymentModel } from '../model/common';
import { Router } from '@angular/router';

export const ANONYMOUS_USER: User = {
  userId: undefined,
  email: undefined,

  surName: undefined,
  name: undefined,
  fatherName: undefined,

  dateOfBirth: undefined,
  birthPlace: undefined,
  gender: undefined,

  phoneNo: undefined,
  countryCode: undefined,

  city: undefined,
  stateId: undefined,
  state: undefined,
  countryId: undefined,
  country: undefined,
  pinCode: undefined,

  profileImage: undefined
};

export const ANONYMOUS_BROADCAST: LoginBroadcast = {
  userLogin: false,
  token: undefined
};

export const ANONYMOUS_WALLET: UserWallet = {
  walletId: false,
  userId: false,
  walletBalance: false,
};


export interface IServerResponse{
  code: any;
  message: any;
  data: any;
}

function _window(): any {
   return window;
}

@Injectable({providedIn: 'root'})
export class AuthService {

  get nativeWindow(): any {
    return _window();
  }

  public loginbroadcast$ = new BehaviorSubject<LoginBroadcast>(ANONYMOUS_BROADCAST);

  public userLogin$ = new BehaviorSubject<User>(ANONYMOUS_USER);

  public userWallet$ = new BehaviorSubject<UserWallet>(ANONYMOUS_WALLET);

  user$: Observable<User> = this.userLogin$.asObservable();

  isLoggedIn$: Observable<boolean> = this.user$.pipe(map((user: User) => !! user.userId));

  public payment$: BehaviorSubject<PaymentModel> = new BehaviorSubject<PaymentModel>(new PaymentModel());

  constructor(
    private router: Router,
    private http: HttpClient) {
    this.loginbroadcast$.subscribe(login => {
      if (login.userLogin){
        this.getUserWallet();
        this.getUserFullProfile();
      }
    }, ( error: any) => {
      this.userLogin$.next(ANONYMOUS_USER);
    });
  }

  // tslint:disable-next-line:no-inferrable-types
  private url = environment.baseUrl;

  isAuthenticated(): Promise<any> {
    const promise = new Promise(
      (resolve, reject) => {
        resolve(this.validatingAccessToken());
      }
    );
    return promise;
  }

  getUserFullProfile(): void{
    this.userProfile().subscribe( response => {
      const user = {...response.data, userId: randomInRange(1500, 4999)};
      localStorage.setItem('user', JSON.stringify(user));
      this.userLogin$.next(user);

      setTimeout(() => {
        this.getUserProfileChek();
      }, 300);
    }, (error: any) => {

    });
  }

  getUserWallet(): void{
    this.getuserWallet().subscribe( response => {
      if (response.code === statusClass.overall_success_response){
        this.userWallet$.next(response.data);
      }else{
        this.userWallet$.next(ANONYMOUS_WALLET);
      }
    }, (error: any) => {
      this.userWallet$.next(ANONYMOUS_WALLET);
    });
  }

  async getUserProfileChek(): Promise<void>{
    const profilePage = JSON.parse(sessionStorage.getItem('profilePageChek'));
    console.log(profilePage);
    if (profilePage){
      if (profilePage.profileStatusChk){
        const checkProfile = await this.checkProfileStatus().toPromise();
        sessionStorage.removeItem('profilePageChek');
        if (checkProfile.profileCompletionFlag === 0){
          this.router.navigate(['user/userprofile']);
        }else{
          try {
              if (profilePage.calbackUrl != null){
                this.router.navigateByUrl(profilePage.calbackUrl);
                const callbackUrl = decodeURIComponent(profilePage.calbackUrl);
                this.router.navigateByUrl(callbackUrl);
              }else{
                this.router.navigate(['/']);
              }
            } catch (e){
              this.router.navigate(['/']);
          }
        }
      }
    }
  }

  validatingAccessToken(): boolean {
    // if (localStorage.getItem('accessToken') != null && localStorage.getItem('user') != null){
    //   return true;
    // }else{
    //   return false;
    // }

    if (localStorage.getItem('accessToken') != null && localStorage.getItem('user') != null){
      const user: User =  JSON.parse(localStorage.getItem('user'));
      if (user != null && user.userId != null){
        this.userLogin$.next(user);
        this.getUserWallet();
      }
      return true;
    }else{
      return false;
    }
  }

  //
  public userProfile(): Observable<IServerResponse> {
    return this.http.get<IServerResponse>(`${this.url}/getProfile`);
  }

  public completeBookPuja(data: CompleteBookModel): Observable<IServerCommonResponse> {
    return this.http.post<IServerCommonResponse>(`${this.url}/completeBookPuja`, {...data});
  }

  public completewalletRecharge(data: CompleteWalletModel): Observable<IServerCommonResponse> {
    return this.http.post<IServerCommonResponse>(`${this.url}/completeWalletRecharge`, {...data});
  }

  public checkProfileStatus(): Observable<IServerCommonResponse> {
    return this.http.get<IServerCommonResponse>(`${this.url}/checkProfileStatus`);
  }

  public getuserWallet(): Observable<IServerResponse> {
    return this.http.get<IServerResponse>(`${this.url}/getUserWallet`);
  }

}

<footer class="container-fluid mt-5">
    <div class="row">
        <div class="col-sm-12 col-md-8">
            <div class="container pt-5 pb-md-5">
                <div class="row">
                    <!-- dummy column -->
                    <div class="col d-none d-lg-block"></div>
                    <!-- end of dummy column -->

                    <div class="col-sm-12 col-md-5 col-lg-4 p-0">
                        <div class="col-12 mb-3">
                            <img src="assets/images/logo_light.svg" alt="logo shaktipeeth" class="img-fluid logo">
                        </div>

                        <div id="termsNprivacy" class="col-12">
                            <ul class="list-unstyled mb-lg-0">
                                <li><a type="button" class="btn regular text-left text-underline p-0" href="privacypolicy">
                                    {{ "FOOTER.PRIVACYPOLICY" | translate }}</a></li>
                                <li><a type="button" class="btn regular text-left text-underline p-0" href="terms-of-use">{{ "FOOTER.TERMSANDCONDITIONS" | translate }}</a></li>
                                <li class="font-14">{{ "FOOTER.COPYRIGHT" | translate }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-7 p-0 d-md-flex flex-md-wrap">
                        <section class="col col-lg-4" aria-labelledby="explore">
                            <h3 id="explore" class="mb-0 font-14 heading">{{ "FOOTER.EXPLORE" | translate }}</h3>
                            <ul class="list-unstyled mb-lg-0">
                                <li *ngFor="let list of exploreList"><a href="{{list.url}}" 
                                        class="btn btn-clear regular text-left p-0 font-14">{{ list.name | translate }}</a>
                                </li>
                            </ul>
                        </section>

                        <section class="col col-lg-4" aria-labelledby="partner">
                            <h3 id="partner" class="mb-0 font-14 heading">{{ "FOOTER.SAFETY" | translate }}</h3>
                            <ul class="list-unstyled mb-lg-0">
                                <!-- <li><button type="button" class="btn btn-clear regular text-left p-0 font-14">Become a
                                        partner</button></li> -->
                                <!-- <li><button type="button" class="btn btn-clear regular text-left p-0 font-14">{{ "BOTTONTEXT.EXPLORE" | translate }}</button></li> -->
                                <li><a type="button"  href="covidprotocol"  class="btn btn-clear regular text-left p-0 font-14">{{ "FOOTER.COVIDPROTOCOL" | translate }}</a></li>
                            </ul>
                        </section>

                        <section class="col-12 col-lg-4" aria-labelledby="contact">
                            <h3 id="contact" class="mb-0 font-14 heading">{{ "FOOTER.CONTACT" | translate }}</h3>
                            <ul
                                class="list-unstyled d-lg-block d-sm-flex d-flex flex-row justify-content-between mb-lg-0">
                                <li><button type="button"  onclick="openContactUsView();"
                                        class="btn btn-clear regular text-left p-0 font-14">{{ "FOOTER.WRITETOUS" | translate }}</button> 
                                </li>
                                <!-- <li><button type="button" class="btn btn-clear regular text-left p-0 font-14">Help &
                                        FAQ</button></li> -->
                                <!-- <li><button type="button" class="btn btn-clear regular text-left p-0 font-14">Site
                                        Map</button></li> -->
                                <li class="d-none d-lg-block">
                                    <a href="https://api.whatsapp.com/send?text=pooja%20booking&phone=%20+919898884357" target="_blank" type="button" class="btn regular text-left p-0"
                                        aria-label="connect with us at whatsapp">
                                        <img src='assets/images/{{ "FOOTER.WHATAPP" | translate }}.svg' class="img-fluid" alt="whatsapp">
                                    </a>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <section class="col-sm-12 col-md-4 px-md-5 d-flex d-md-block social" aria-labelledby="journey">
            <div class="footer_hrefDiv" style="">
                <div class="col-md-12 pt-5 px-md-0">
                    <h2 id="journey" class="font-22 journey--title" style="padding-right: 3rem !important;">through the journey of life, and after</h2>
                    <a href="{{dwnapp?.appUrl}}" target="_blank">
                        <img src="assets/images/googleplay.png" alt="download shaktipeeth app from google play"
                            class="img-fluid download">
                    </a>
                </div>
                <div class="udankhatola_logoDiv" style="display: flex;flex-direction: column;">
                    <label style="font-weight:600;font-size: 17px;">Book Ropeway</label>
                    <img src="assets/images/Udankhatola_logo.png" class="udhankhatola_img" (click)="redirectToUdhanKhatola()">
            
                </div>
            </div>

            <div class="col-md-12 px-md-0">
                <ul class="list-inline mt-4 social-connect mb-0 text-right text-md-left">
                    <li class="list-inline-item pr-md-2 d-block d-md-inline">
                        <a href="https://www.instagram.com/shaktipeethdigital/" target="_blank">
                            <span class="font-18 ic-">instagram</span>
                        </a>
                    </li>
                    <li class="list-inline-item pr-md-2 d-block d-md-inline">
                        <a href="https://www.facebook.com/shaktipeethdigital/" target="_blank">
                            <span class="font-18 ic-">facebook</span>
                        </a>
                    </li>
                    <li class="list-inline-item pr-md-2 d-block d-md-inline">
                        <a href="https://twitter.com/ShaktipeethD" target="_blank">
                            <span class="font-18 ic-">twitter</span>
                        </a>
                    </li>
                    <li class="list-inline-item pr-md-2 d-block d-md-inline">
                        <a href="https://www.linkedin.com/company/shaktipeeth-digital/" target="_blank">
                            <span class="font-18 ic-">linkedin</span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</footer>

<app-contactus></app-contactus>
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ANONYMOUS_USER, AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/model/user';
import { IHeaderLink } from './api/menu.interface';
import { HEADERMENULINKS , LANGULAGES, statusClass} from 'src/app/model/constants';
import { MenuApiService } from './api/menu.api.service';
import { mapOrder } from 'src/app/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  // template: '<h2>menu</h2>',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private router: Router, public authService: AuthService,
              private menuApiService: MenuApiService,
              public translate: TranslateService) {
                console.log(translate.currentLang);
                this.currentLang = translate.currentLang;
                this.currentLang = LANGULAGES.find(lang => lang.code === translate.currentLang).name;
                translate.onLangChange.subscribe(param => {
                  this.currentLang = param.lang;
                  this.currentLang = LANGULAGES.find(lang => lang.code === translate.currentLang).name;
                });
  }

  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;

  isTabMobileView: boolean;
  headerList: IHeaderLink[];
  public language: any[] = LANGULAGES.map((data: any) => data);

  currentLang: string;
  userProfile: any;
  // public countries: KeyValue<string, string>[] = COUNTRIES.map(data => ({
  //   key: data.country_name,
  //   value: data.code
  // }));

  ngOnInit(): void {
    this.onResize(null);
    this.getHeaderLinks();
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.isLoggedOut$ = this.authService.isLoggedIn$.pipe(map(isLoggedIn => !isLoggedIn));
    this.loadUserDetails();
    this.authService.userLogin$.subscribe( res => {
      this.userProfile = res;
    });
    // When the user scrolls the page, execute stickyHeader
    window.onscroll = function () { stickyHeader() };

    // Get the header
    const header = document.getElementById('header');
    const logo = document.getElementById('logo');

    const sticky = header.offsetTop;

    function stickyHeader(): void {
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        logo.setAttribute('src', 'assets/images/logo_orange.svg');
      } else {
        header.classList.remove('sticky');
        logo.setAttribute('src', 'assets/images/logo.svg');
      }
    }
  }

  getHeaderLinks(): void{
    // this.menuApiService.getHeaderLink().subscribe(response => {
    //   if (response.code === statusClass.overall_success_response){
    //     if (response.data && response.data.header){
    //       const headerlink = HEADERMENULINKS.filter(a => response.data.header.includes(a.code));
    //       this.headerList = mapOrder(headerlink, response.data.header, 'code');
    //     }
    //   }
    // }, (error: any) => {
    //   console.log('error on header');
      // const list = [ 'pooja', 'astrology', 'ropeways', 'about' ];
      const list = [ 'pooja', 'ropeways','ourservices', 'about' ];
      this.headerList = HEADERMENULINKS.filter(a => list.includes(a.code));
    // });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void{
    if (window.innerWidth <= 1023){
      this.isTabMobileView = true;
    }else{
      this.isTabMobileView = false;
    }
  }

  loadUserDetails(): void{
    const user: User =  JSON.parse(localStorage.getItem('user'));
    if (user != null && user.userId != null){
      this.authService.userLogin$.next(user);
      this.authService.getUserWallet();
    }
  }

  async logout(): Promise<void> {
    try{
      const logout  = await this.menuApiService.logout().toPromise();
      if (logout.code === statusClass.overall_success_response) {
        this.authService.userLogin$.next(ANONYMOUS_USER);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        this.router.navigate(['/']);
      } else {
        this.authService.userLogin$.next(ANONYMOUS_USER);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        this.router.navigate(['/']);
      }
    } catch (r){
      this.authService.userLogin$.next(ANONYMOUS_USER);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      this.router.navigate(['/']);
    }
  }

  redirectToUdhanKhatola(){
    let url="https://udankhatola.com";
    window.open(url, "_blank");
  }


}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

// continner
import { DefaultLayoutComponent } from './containers';

import { AppComponent } from './app.component';
import { AppLanguageComponent, AppLanguagesResolver } from './lang.component';
import { P404Component } from './views/error/404.component';


import { CoreModule } from './core/core.module';
import { MenuPageModule } from './views/common/menu/menu.module';
import { FooterPageModule } from './views/common/footer/footer.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PrivacyPolicyComponent } from './views/privacypolicy/policy/policy.component';
import { TermConditionComponent } from './views/termsofuse/condition/condition.component';
import { SecurePaymentComponent } from './views/securePayment/securepayment.component';
import { TermsOfUseApiService } from './views/termsofuse/api/trermofuse.api.service';
import { PolicyApiService } from './views/privacypolicy/api/policy.api.service';
import { SharedModule } from './shared.module';
import { CovidComponent } from './views/common/covid/covid.component';
import { NewComponentComponent } from './views/new-component/new-component.component';
import { OurservicesComponent } from './views/ourservices/ourservices.component';


const APP_CONTAINERS = [
  DefaultLayoutComponent,
];


@NgModule({
  declarations: [
    AppComponent,
    AppLanguageComponent,
    ...APP_CONTAINERS,
    TermConditionComponent,
    PrivacyPolicyComponent,
    SecurePaymentComponent,
    P404Component,
    CovidComponent,
    NewComponentComponent,
    OurservicesComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    MenuPageModule,
    HttpClientModule,
    AppRoutingModule,
    FooterPageModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [AppLanguagesResolver, TermsOfUseApiService, PolicyApiService,
  {
    provide: APP_BASE_HREF,
    useFactory: (s: PlatformLocation) => trimLastSlashFromUrl(s.getBaseHrefFromDOM()),
    deps: [PlatformLocation]
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }


function trimLastSlashFromUrl(baseUrl: string): any {
  if (stringIsNullOrWhitespace(baseUrl)) {
    return null;
  } else if (baseUrl[baseUrl.length - 1] === '/') {
    const trimmedUrl = baseUrl.substring(0, baseUrl.length - 1);
    return trimmedUrl;
  }
}

function stringIsNullOrWhitespace( input: any): boolean {
  if (typeof input === 'undefined' || input == null){
    return true;
  }
  return input.replace(/\s/g, '').length < 1;
}

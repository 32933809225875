<section class="container-fluid heading mt-3">
    <h1 class="py-2">Covid guideline</h1>
</section>
<section class="container">
    <h2 class="mt-5 mb-4"> Our Covid 19 Protocol:</h2>
    <p> Please maintain safety protocol in pandemic. Maintain distance and wear mask. Use soap and sanitizer in regular interval. Take vaccine.
        Our priests and staff follow Covid appropriate behaviors and protocol.
        Do's</p>
    <p> Wash your hands often with soap / sanitizer. Wear a face mask.</p>
    <p> Maintain at least 1 meter distance, observe social distancing at all times. Get Vaccinated.</p>
       
    <h2 class="mt-5 mb-4"> Don’ts</h2>
    <p> Don't Touch Face.</p>
    <p>    Avoid contact with Sick People.</p>
    <p>    Always cover your cough and sneeze</p>
    <p>    Avoid non-essential social gatherings at any places.</p>
    <p>    Avoid shaking hands and hugging as a matter of greeting</p>
    <p>    Avoid frequent trips to market & any crowded places. Stay at Home</p>
        
    <p>If you have symptoms like cough, fever or difficulty in breathing, avoid any kind of exposure and immediately call the government helpline numbers.</p>
    <p>Temple management ensures that the premises are disinfected using COVID-19 safety protocol, including social distancing and use of masks is followed as directed by government. Please follow Covid appropriate behaviors every day and help keep Covid 19 away.
    </p>
</section>

import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteBookModel, CompleteWalletModel, PaymentModel } from 'src/app/model/common';
import { environment, statusClass } from 'src/app/model/constants';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: 'securepayment.component.html'
})
export class SecurePaymentComponent implements OnInit{

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.orderId = this.route.snapshot.paramMap.get('orderId');
  }

  orderId: string;
  options: any;
  payment: PaymentModel;

  ngOnInit(): void {
    this.authService.payment$.subscribe(payment => {
      if (payment.amount != null){
        this.payment = payment;
        this.options = {
          key: environment.razorpay_key,
          image: 'https://shaktipeethassetslive.s3.ap-south-1.amazonaws.com/images/home-page-banner-new-logo.png',
          handler: (response: any) => {
            this.paySuccess(response);
          },
          modal: {
            ondismiss: () => {
              this.payOndismiss();
            }
          },
          theme: {
            color: '#FF9445'
          },
          notes: {
            address: 'Shaktipeeth Digital Corporate Office'
          },
          order_id: this.orderId,
          amount: parseFloat(payment.amount) * 100.00,
          currency: payment.currency,
          name: payment.name,
          description: payment.description,
          prefill: payment.prefill,
        };

        setTimeout(() => {
          // this.authService.payment$.next(new PaymentModel());
          this.pay();
        }, 300);
      }
      else{
        this.router.navigate(['/']);
      }
    });
  }

  pay(): void{
    const rzp1 = new this.authService.nativeWindow.Razorpay(this.options);
    rzp1.open();

    rzp1.on('payment.failed', (response: any) => {
      console.log(response);
      this.router.navigate(['/']);
    });

  }

  paySuccess(response: any): void {
    if (this.payment.transectionType === statusClass.transaction_type_wallet){
      const completeOderModel = new CompleteWalletModel();
      completeOderModel.orderId = this.payment.orderId;
      completeOderModel.amount = this.payment.amount;

      completeOderModel.gatewayOrderId = this.payment.razorpayOrderId;
      completeOderModel.gatewayPaymentId = response.razorpay_payment_id;

      completeOderModel.walletId = this.payment.walletId;
      completeOderModel.couponId = this.payment.couponId;
      completeOderModel.denominationId = this.payment.denominationId;

      this.authService.completewalletRecharge(completeOderModel).subscribe(res => {
        if (res.code === statusClass.overall_success_response) {
          if (this.payment.transectionType === statusClass.transaction_type_wallet){
            window.location.href = this.baseHref + '/user/userprofile/wallet?rechageStatus=1&&orderId=' + this.payment.orderId;
          } else {
            window.location.href = this.baseHref + '/';
          }
        } else {
          Swal.fire({
            icon: 'error',
            text:  res.message ? res.message : 'Something is problem. Please try again.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            denyButtonText: ``,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              setTimeout(() => {
                window.location.href = this.baseHref + '/';
              }, 1000);
            } else if (result.isDenied) {
              // Swal.fire('Changes are not saved', '', 'info')
            }
          });
        }
      }, (error: any) => {
        Swal.fire({
          icon: 'error',
          text:  'Something is problem. Please try again.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          denyButtonText: ``,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.href = this.baseHref + '/';
            }, 1000);
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
        });
      });
    } else {
      const completeOderModel = new CompleteBookModel();
      completeOderModel.amount = this.payment.amount;
      completeOderModel.orderId = this.payment.orderId;
      completeOderModel.razorpayOrderId = this.payment.razorpayOrderId;
      completeOderModel.razorpayTransactionId = response.razorpay_payment_id;

      completeOderModel.couponId = this.payment.couponId ? this.payment.couponId : -1;

      this.authService.completeBookPuja(completeOderModel).subscribe(res => {
        if (res.code === statusClass.overall_success_response){
            window.location.href =  this.baseHref + '/user/order/' + completeOderModel.orderId; // 'user/bookinghistory';
          //  this.router.navigate(['user/order', completeOderModel.orderId]);
        } else {
          Swal.fire({
            icon: 'error',
            text:  res.message ? res.message : 'Something is problem. Please try again.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            denyButtonText: ``,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              setTimeout(() => {
                window.location.href = this.baseHref + '/';
              }, 1000);
            } else if (result.isDenied) {
              // Swal.fire('Changes are not saved', '', 'info')
            }
          });
        }
      }, (error: any) => {
        Swal.fire({
          icon: 'error',
          text:  'Something is problem. Please try again.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          denyButtonText: ``,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.href = this.baseHref + '/';
            }, 1000);
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
        });
      });
    }
  }

  payOndismiss(): void {
    setTimeout(() => {
      window.location.href = this.baseHref + '/';
    }, 200);
  }

}

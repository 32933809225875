import { Component, OnInit, Inject  } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LANGULAGES } from './model/constants';
// import 'rxjs/add/operator/pairwise';

@Component({
    selector: 'app-lang',
    template: ''
  })
export class AppLanguageComponent implements OnInit{
  
  constructor(@Inject(APP_BASE_HREF) public baseHref: string, private router: Router, private route: ActivatedRoute,
              public param: Router, public translate: TranslateService){
    // console.log(baseHref)
  }

  ngOnInit(): void{
    const callbackUrl = this.route.snapshot.data['callbackUrl'];

    const lang = this.route.snapshot.params.id;
    const langObj = LANGULAGES.find(langs => langs.code === lang);
    if (langObj != null){
        localStorage.setItem('lang', JSON.stringify(langObj));
        localStorage.setItem('langParam', langObj.id);
        if (callbackUrl != null && !callbackUrl.includes('lang/')){
          window.location.href = callbackUrl;
        }else{
          // console.log(this.baseHref);
          // setTimeout(() => {
            window.location.href = this.baseHref + '/';
          //}, 3500);
        }
    }
  }
}




import { Injectable, } from '@angular/core';
import { Location, APP_BASE_HREF  } from '@angular/common';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot,  } from '@angular/router';

@Injectable()
export class AppLanguagesResolver implements Resolve<string>{
    location: Location;
    constructor(location: Location){
      this.location = location;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
      const callbackUrl = this.location.path().substring(1);
      if (callbackUrl != null && callbackUrl.length > 1){
        return callbackUrl;
      }
      return null;
    }
}


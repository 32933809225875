export interface IMenu {
    name: string;
    code: string;
    url: string;
}

export class PaymentModel {
    transectionType?: number;

    orderId: any;
    razorpayOrderId: any;

    amount: any;
    currency: any;
    name: any;
    description: any;
    prefill: PrefillModel;

    // wallet
    walletId?: any;
    couponId?: any;
    denominationId?: any;
}

export class PrefillModel {
    name: any;
    email: any;
    contact: any;
}

export class CompleteBookModel {
    amount: any;
    orderId: any;
    couponId: any;
    razorpayOrderId: any;
    razorpayTransactionId: any;
}

export class CompleteWalletModel {
    amount: any;
    orderId: any;
    walletId: any;
    couponId: any;
    gatewayOrderId: any;
    denominationId: any;
    gatewayPaymentId: any;
}

export interface IServerCommonResponse {
    code: any;
    message?: any;
    data?: any;
    geonames?: any;
    profileCompletionFlag?: any;
}

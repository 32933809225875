import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { IContactUs } from '../api/footer.interface';
declare function closeEnquiry(): any; // just change here from arun answer.

@Component({
  selector: 'app-contactus',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss']
})
export class ContactUsSidepanelComponent implements OnInit, DoCheck {

  // @Input() enquireinfo: IPooja;
  @Input() location: string;
  loginloading: boolean;

  constructor(
    private route: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private authService: AuthService,
  ) { }

  contactUs = {} as IContactUs;
  selected: Date | null;

  ngOnInit(): void {
    // console.log(this.enquireinfo);
    this.authService.userLogin$.subscribe(user => {
      if (user.userId != null && user.userId > 0){
        this.contactUs.email = user.email;
        this.contactUs.name = user.name;
        this.contactUs.phone = user.phoneNo;
      } else {
        this.contactUs.email = null;
      }
    });
  }

  ngDoCheck(): void {
    // console.log(this.enquireinfo);
  }

  sumbitEnq(form: any): void {
    console.log(this.contactUs);
    Swal.fire({
      icon: 'success',
      title: 'Your request was send',
      showConfirmButton: false,
      timer: 1500,
      background: '#fff',
      backdrop: `
        rgba(0,0,123,0.4)
        no-repeat
      `
    });
  }
}

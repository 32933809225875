<main>
    <div class="container">
        <div class="row justify-content-between align-items-center">

            <div class="container mt-4">
                <h3 class="sectionHeading fontCourgette mb-5">{{termAndUse?.title}}</h3>
                <div [innerHtml]="termAndUse?.description"></div>
            </div>

        </div>
    </div>
</main>
<section class="sidepanel-backdrop h-100 position-fixed" id="contactuspanel" aria-labelledby="">
    <div class="sidepanel position-fixed h-100" id="sidePanelcontactus">
        <form  name="form" (ngSubmit)="enqpage.form.valid && sumbitEnq(enqpage)" #enqpage="ngForm" novalidate>
            <header class="sidepanel-header justify-content-between align-items-center d-flex py-3 px-5">
                <div class="col-auto p-0">
                    <h2 class="font-22" id="">{{ "FOOTER.WRITETOUS" | translate }}</h2>
                </div>
                <div class="col-auto p-0">
                    <button type="button" class="close" aria-label="Close" onclick="closeContactUsView();">
                        <span class="ic-" aria-hidden="true">close</span>
                    </button>
                </div>
            </header>

            <div class="sidepanel-body px-5 py-3">

                <div>
                    <!-- <h3 class="font-36 txt-muted bold mb-5">Enquire</h3>  -->
                    <p class="font-16 txt-muted mb-5">{{ "SITETEXT.WRITETOUSTXT" | translate }}</p> 
                    <!-- <h3 class="font-28 txt-warning bold ">Your Trip</h3> -->
                    <div class="col-12">
                        <section class="row justify-content-between mb-4" aria-labelledby=""> 
                            <mat-form-field class="example-full-width" [floatLabel]="'always'">
                                <mat-label>{{ "ENQUIREFORM.SUBJECT" | translate }}</mat-label>
                                <input required matInput placeholder='{{ "ENQUIREFORM.SUBJECT" | translate }}' [(ngModel)]="contactUs.subject"   
                                id="enqsub" name="enqsub" #enqsub="ngModel">
                                <mat-error *ngIf="enqpage.submitted && enqsub.invalid" class="mt-3">
                                    {{ "ENQUIREFORM.SUBJECT" | translate }}&nbsp;{{ "ENQUIREFORM.ISREQUIRED" | translate }}</mat-error>
                            </mat-form-field>
                            
                        </section>
                        
                        <section class="row justify-content-between mb-4" aria-labelledby="">
                            <mat-form-field class="example-full-width" [floatLabel]="'always'">
                                <mat-label>{{ "ENQUIREFORM.NAME" | translate }}</mat-label>
                                <input required matInput placeholder='{{ "ENQUIREFORM.NAME" | translate }}' [(ngModel)]="contactUs.name"   
                                id="enqname" name="enqname" #enqname="ngModel">
                                <mat-error *ngIf="enqpage.submitted && enqname.invalid" class="mt-3">
                                    {{ "ENQUIREFORM.NAME" | translate }}&nbsp;{{ "ENQUIREFORM.ISREQUIRED" | translate }}</mat-error>
                            </mat-form-field>
                            
                        </section>
                        
                        <!-- <section class="row justify-content-between mb-4" aria-labelledby="">
                            <mat-form-field class="example-full-width" [floatLabel]="'always'">
                                <mat-label>Email</mat-label>
                                <input required matInput placeholder="email"  [(ngModel)]="contactUs.email"  
                                 id="enqemail" name="enqemail" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" #enqemail="ngModel">
                               <mat-error *ngIf="enqpage.submitted && enqemail.invalid && enqemail.errors?.required" class="mt-3">
                                    Email is required</mat-error>
                                <mat-error *ngIf="enqpage.submitted && enqemail.invalid && enqemail.errors?.pattern && !enqemail.errors?.required" class="mt-3">Please enter correct email format.</mat-error>
                            </mat-form-field>
                        </section>
                        <section class="row justify-content-between mb-4" aria-labelledby="">
                            <mat-form-field [floatLabel]="'always'">
                                <mat-label>Mobile Number</mat-label>
                                <span matPrefix>+91 &nbsp;</span>
                                <input type="tel" matInput placeholder="00000-00000"  [(ngModel)]="contactUs.phone" 
                                     aria-describedby="help"  id="enqphone" name="enqphone" #enqphone="ngModel">
                               <mat-error *ngIf="enqpage.submitted && enqphone.invalid" class="mt-3">
                                    phone is required</mat-error>
                            </mat-form-field>
                        </section> -->
                        
                        <!-- <section class="row justify-content-between mb-4" aria-labelledby="">
                            <mat-form-field class="example-full-width" [floatLabel]="'always'">
                                <mat-label>Date</mat-label>
                                <input required matInput [matDatepicker]="picker" readonly placeholder="Date" [(ngModel)]="contactUs.pujaDate" 
                                 (click)="picker.open()" id="enqdate" name="enqdate" #enqdate="ngModel">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                               <mat-error *ngIf="enqpage.submitted && enqdate.invalid" class="mt-3">
                                    Date is required</mat-error>
                            </mat-form-field>
                        </section> -->

                        <section class="row justify-content-between mb-4" aria-labelledby="">
                            <label for="enqmessage">{{ "ENQUIREFORM.MESSAGE" | translate }} *</label>
                            <textarea required class="form-control" id="message" rows="5" [(ngModel)]="contactUs.message" 
                            id="enqmessage" name="enqmessage" #enqmessage="ngModel"></textarea>
                           <mat-error *ngIf="enqpage.submitted && enqmessage.invalid" class="mt-3 font-12">
                            {{ "ENQUIREFORM.MESSAGE" | translate }}&nbsp;{{ "ENQUIREFORM.ISREQUIRED" | translate }}</mat-error>
                        </section>
                    </div>
                </div>
            </div>

            <footer class="sidepanel-footer position-absolute w-100 px-5 py-3">
                <div class="row justify-content-between align-items-center">
                    <div class="col-auto p-0">
                        <!-- <p class="mb-0 txt-muted">Submit to someone..</p> -->
                    </div>
                    <div class="col-auto p-0">
                        <button type="submit"  class="btn btn-primary btn-bg-orange btn-block bold text-uppercase btn-login">
                            <ng-container *ngIf="!loginloading">&nbsp;{{ "ENQUIREFORM.SEND" | translate }}</ng-container>
                            <span *ngIf="loginloading" class="spinner-grow spinner-grow-sm" role="status"
                            aria-label="loading"></span>
                        </button>
                    </div>
                </div>
            </footer>
        </form>
    </div>
</section>

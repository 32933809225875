import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'src/app/model/constants';
import { IServerCommonResponse } from 'src/app/model/common';
import { Observable } from 'rxjs';

@Injectable()
export class TermsOfUseApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  // tslint:disable-next-line:no-inferrable-types
  private url = environment.baseUrl;

  public getTermsOfUse(): Observable<IServerCommonResponse> {
    return this.http.get<IServerCommonResponse>(`${this.url}/getTermsOfUse`);
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { AppLanguageComponent, AppLanguagesResolver } from './lang.component';
import { P404Component } from './views/error/404.component';

import { AuthLoginGuard } from './services/auth-login-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { TermConditionComponent } from './views/termsofuse/condition/condition.component';
import { PrivacyPolicyComponent } from './views/privacypolicy/policy/policy.component';
import { SecurePaymentComponent } from './views/securePayment/securepayment.component';
import { CovidComponent } from './views/common/covid/covid.component';
import { OurservicesComponent } from './views/ourservices/ourservices.component';

const routes: Routes = [

  { path: '', component: DefaultLayoutComponent, data: { title: 'Home' },
    children: [
      { path: '', loadChildren: () => import('./views/home/home.module').then((m) => m.HomePageModule), },
      { path: 'lang/:id',
        component: AppLanguageComponent,
        resolve: {
          callbackUrl: AppLanguagesResolver
        },
      },
      { path: 'pooja',
      // canActivate: [AuthGuard] ,
      loadChildren: () => import('./views/pooja/pooja.module').then((m) => m.PoojaPageModule), },

      { path: 'astrology',
     // canActivate: [AuthGuard] ,
      loadChildren: () => import('./views/astrology/astrology.module').then((m) => m.AstrologyPageModule), },

      { path: 'ropeways',
      // canActivate: [AuthGuard] ,
      loadChildren: () => import('./views/ropeways/ropeways.module').then((m) => m.RopewaysPageModule), },

      { path: 'explores',
      // canActivate: [AuthGuard] ,
      loadChildren: () => import('./views/explore/explore.module').then((m) => m.ExploreAppPageModule), },

      { path: 'gallery',
      // canActivate: [AuthGuard] ,
      loadChildren: () => import('./views/shaktipeethTv/shaktipeethtv.module').then((m) => m.ShaktiPeethTvAppPageModule), },

      { path: 'about',
      loadChildren: () => import('./views/about/about.module').then((m) => m.AboutAppPageModule), },

      { path: 'muhurat',
      loadChildren: () => import('./views/festivalCalendar/festivalcalendar.module').then((m) => m.FestivalCalendarModule), },

      { path: 'login',
      canActivate: [AuthLoginGuard] ,
      loadChildren: () => import('./views/login/login.module').then((m) => m.LoginPageModule), },


      { path: 'signup', loadChildren: () => import('./views/signup/signup.module').then((m) => m.SignUpPageModule), },

      { path: 'user',
      canActivate: [AuthGuard] ,
      loadChildren: () => import('./views/user/user.module').then((m) => m.UserAppPageModule), },

      { path: 'terms-of-use' , component: TermConditionComponent},
      { path: 'privacypolicy' , component: PrivacyPolicyComponent},
      { path: 'covidprotocol' , component: CovidComponent},
      { path: 'ourservices' , component: OurservicesComponent},
      { path: 'not-found', component: P404Component, data: {message: 'Page not found!'} },
      // { path: '**', redirectTo: '/not-found' }
    ]
  },
  { path: 'securepayment/:orderId' , component: SecurePaymentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

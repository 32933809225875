import { Component, OnInit, ChangeDetectionStrategy, Input  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of} from 'rxjs';
import { statusClass } from 'src/app/model/constants';
import { TermsOfUseApiService } from '../api/trermofuse.api.service';


@Component({
  selector: 'app-termcondition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class TermConditionComponent implements OnInit {

   constructor(
    private router: Router,
    private route: ActivatedRoute,
    private termOfUse: TermsOfUseApiService
  ) {

  }

  termAndUse: any;

  ngOnInit(): void {
    this.termOfUse.getTermsOfUse().subscribe(res => {
      console.log(res);
      if (res.code === statusClass.overall_success_response){
        this.termAndUse = res.data;
      }
    }, (error: any) => {

    });
  }

}

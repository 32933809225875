<main>
    <!-- carousel to be added -->
    <div class="mt-4">
        <div _ngcontent-xpo-c184="" class="banner" style="background-image: url();"><img _ngcontent-xpo-c184="" alt="..." class="img-fluid w-100" 
            src="https://shaktipeethassetslive.s3.ap-south-1.amazonaws.com/images/about-header-dark-min.jpg"></div>
            <div _ngcontent-mqv-c185="" class="container mt-5" style="border-bottom: 1px solid #ff9445; padding-bottom: 53px;">
        <div class="container mt-5" style="text-align: center;">
            <h2 class="mb-3">Our Services at “Shaktipeeth Digital”</h2>
            <h4>Shaktipeeth endeavours to provide a memorable experience to the
                customers by providing best-in-class food and blissful memories at our
                facilities</h4>
        </div>
        </div>
        <div _ngcontent-mqv-c185="" class="container mt-5" style="border-bottom: 1px solid #ff9445; padding-bottom: 53px;">
            <div _ngcontent-mqv-c185="" class="row align-items-center">
                <div _ngcontent-mqv-c185="" class="col-sm-12 col-md-8 text-center text-md-right">
                <h3 _ngcontent-mqv-c185="">Shaktipeeth Food Court (Made with Love)</h3>
                <p _ngcontent-mqv-c185="" class="mb-0 plx" style="font-size: 20px;">
                    The food court offers specially crafted meal options to the taste and belief
                        of the visitors with our special focus on food quality and safety. Scrumptious
                        food & Beverage options are the ultimate pacifiers.</p>
                    </div>
                    <div _ngcontent-mqv-c185="" 
                        class="col-sm-12 col-md-4"><img _ngcontent-mqv-c185="" class="img-fluid" style="box-shadow: rgba(0, 0, 0, 0.3) 3px 8px 11px 0px;" src="../../../assets/images/Food Curt.jpg">
                    </div>
                </div>
            </div>
        
            <div _ngcontent-mqv-c185="" class="container mt-5" style="border-bottom: 1px solid #ff9445; padding-bottom: 53px;">
                <div _ngcontent-mqv-c185="" class="row align-items-center">
                    <div _ngcontent-mqv-c185="" 
                            class="col-sm-12 col-md-4"><img _ngcontent-mqv-c185="" class="img-fluid"  style="box-shadow: rgba(0, 0, 0, 0.3) 3px 8px 11px 0px;" src="../../../assets/images/Picture3.jpg">
                        </div>
                    <div _ngcontent-mqv-c185="" class="col-sm-12 col-md-8 text-center text-md-left">
                    <h3 _ngcontent-mqv-c185="">Shaktipeeth Souvenir (Memories are forever)</h3>
                    <p _ngcontent-mqv-c185="" class="mb-0 plx"  style="font-size: 20px;">
                    “Blissful memories” is our motto and visitors can pick from a selection of items  and create long-lasting memories.</p>
                        </div>
                        
                    </div>
                </div>

                <div _ngcontent-mqv-c185="" class="container mt-5" style="border-bottom: 1px solid #ff9445; padding-bottom: 53px;">
                    <div _ngcontent-mqv-c185="" class="row align-items-center">
                        <div _ngcontent-mqv-c185="" class="col-sm-12 col-md-8 text-center text-md-right">
                        <h3 _ngcontent-mqv-c185="">Shaktipeeth Leisure</h3>
                        <p _ngcontent-mqv-c185="" class="mb-0 plx"  style="font-size: 20px;">
                            Our leisure, relaxation and entertainment options ensure that visitors are able to rejoice in their journey.</p>
                            </div>
                            <div _ngcontent-mqv-c185="" 
                                class="col-sm-12 col-md-4"><img _ngcontent-mqv-c185="" class="img-fluid" style="box-shadow: rgba(0, 0, 0, 0.3) 3px 8px 11px 0px;" src="../../../assets/images/Shaktipeeth Leisure.jpg">
                            </div>
                        </div>
                    </div>
                
                <div _ngcontent-mqv-c185="" class="container mt-5" style="border-bottom: 1px solid #ff9445; padding-bottom: 53px;">
                    <div _ngcontent-mqv-c185="" class="row align-items-center">
                        <div _ngcontent-mqv-c185="" 
                                class="col-sm-12 col-md-4" ><img _ngcontent-mqv-c185="" class="img-fluid"src="../../../assets/images/photography1.jpeg"  style="box-shadow: rgba(0, 0, 0, 0.3) 3px 8px 11px 0px;">
                            </div>
                        <div _ngcontent-mqv-c185="" class="col-sm-12 col-md-8 text-center text-md-left">
                        <h3 _ngcontent-mqv-c185="">Shaktipeeth Photography</h3>
                        <p _ngcontent-mqv-c185="" class="mb-0 plx"  style="font-size: 20px; ">
                            Life has no rewinds, and we capture the ultimate moments of your journey with our photography specialist.</p>
                            </div>
                            
                        </div>
                    </div>
        <!-- teams -->
        <!-- <div class="container mt-5">
            <div style="padding-bottom: 42px;">
                <h2>Meet our leaders</h2>
                <p>There are four masterminds behind the formation and successful working of Shaktipeeth. Our leaders have global experience in their domains. They are always ready to welcome people who are rigorous learners and can add stars to the company with their expertise and knowledge. </p>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="span-box leader-box">
                        <div class="span-row-sep">
                            <div class="span-col-1">
                                <div class="profile-img"><img alt=""
                                    src="assets/images/parshant-jhawar_team-1629363307.jpg">
                                </div>
                                <div class="profile-name">Prashant Jhawar</div>
                                <div class="profile-designation">Founder, CEO &amp; Director</div>
                                <div class="prof-cont">
                                    <p>Mr. Prashant Jhawar belongs to a Business Family with strong entrepreneurial genes and is a 2nd generation Entrepreneur. Prashant joined the family business in 1982 and since then has created a track record of nurturing start-ups, scale-ups & buy-outs, involving a wide spectrum of verticals, including Manufacturing, Media, IT/ITeS, Transport, Religion & Wellness to name a few. Prashant founded Usha Martin Telecom in 1994, a GSM cellular network in the city of Kolkata, India in partnership with Telekom Malaysia and other international investors. He successfully led the trade sale of this venture to Hutchison Whampoa in July 2000.
                                    </p>

                                    <p>Prashant also acquired Eppixcomm Tech from Logica, an FTSE 250 company, in 2005. A deal that was completed in circa 5 months. Eppixcomm later became part of the Eppix Group Holdings and has been an integral part since then. During the early nineties, Prashant established Eppix group Holdings (Eppix), which evolved into a convergent customer care and billing solution provider, spread across the Americas, EMEA, and Asia. Later he led the transformation of Eppix from a legacy business into a cloud-based, neo-age solution provider, adding more solutions & services to its Portfolio. He founded Shaktipeeth Digital in 2019 to bridge the gap between the shrine and devotee digitally.
                                    </p>
                                    <p>Prashant has been instrumental in streamlining the operations of the group, for increased efficiency and is also leading various initiatives within the organization, for sustained growth. Over the years, Prashant has been actively contributing to various UK / India Industry bodies. In the year 2001, Prashant, along with his family, moved to the UK, to spearhead the various business initiatives of the group, which culminated into more than one acquisition in the UK. Prashant has been living in London since then.</p>
                                </div>
                                <div class="social">

                                </div>
                            </div>
                        </div>
                        <div class="span-row-sep">
                            <div class="span-col-1">
                                <div class="profile-img"><img alt=""
                                    src="assets/images/ravi-goenka_team-1629363214.jpg">
                                </div>
                                <div class="profile-name">Ravi Goenka</div>
                                <div class="profile-designation">Director</div>
                                <div class="prof-cont">
                                    <p>
                                        Being a Chartered Accountant and Company Secretary by profession, Ravi has been with the group since 1988 and has played significant leadership roles in several company-wide initiatives. Ravi has been closely working with the Founder for nearly 3 decades. Ravi has strong Financial and M&A experience and is also adept at the restructuring of businesses for various verticals that have been founded and acquired during this period. Ravi is based out of London, UK.
                                    </p>
                                </div>
                                <div class="social">

                                </div>
                            </div>
                        </div>
                        <div class="span-row-sep">
                            <div class="span-col-1">
                                <div class="profile-img"><img alt=""
                                    src="assets/images/dipak_kaplish.jpg">
                                </div>
                                <div class="profile-name">Deepak Kaplish</div>
                                <div class="profile-designation">Business Advisor</div>
                                <div class="prof-cont">
                                    <p>
                                        Deepak is a performance-driven Professional, bringing in rare levels of business acumen and achievements of over 25 years in Commercial Operations, Media & PR, Project Coordination, Project Management, Strategic Planning & Business Development, and experience of directly handling over 3 million ropeway passengers annually.
                                    </p>
                                    <p>
                                        Deepak has a management degree from the Institute of Management Technology, Ghaziabad and a Post-Graduation degree in Commerce from the College of Commerce and Management Studies, Udaipur 
                                    </p>
                                    <p>
                                        Deepak has successfully handled strategic planning projects with cross-functional knowledge in Operations, Risk Control, Business Development, Marketing & Sales and Corporate Social Responsibility. He has worked on diverse business activities of Ropeway, Hospitality, Tourism, Real Estate Project Development, and e-commerce initiatives.
                                    </p>
                                    <p>
                                        Deepak is a keen analyst with exceptional relationship management skills in liaising with Government Departments and other External Agencies. He is disciplined & motivated with excellent interpersonal communication & Organizational skills with proven abilities in team management and customer relationship management. Adroit in ensuring delivery of high-quality services to support customers’ needs and continued high customer satisfaction.
                                    </p>
                                </div>
                                <div class="social">

                                </div>
                            </div>
                        </div>

                        <div class="span-row-sep">
                            <div class="span-col-1">
                                <div class="profile-img"><img alt=""
                                        src="assets/images/niraj-basotia_team-1629363135.jpg">
                                </div>
                                <div class="profile-name">Niraj Basotia</div>
                                <div class="profile-designation">Technical Advisor</div>
                                <div class="prof-cont">
                                    <p>A business and technology-driven professional, Niraj has global experience of 25+ years in the IT Solutions and Consulting business. Niraj joined NIIT Ltd in the year 1991 and spent circa 15 years with the NIIT group, primarily with NIIT Technologies, during which he performed various roles in India, APAC, and Europe. Niraj learned the skills around Project Management, Business Development, and Client Relationship building during this period.</p>
                                    <p>Niraj moved to IBM in the year 2007. Initially, he performed the role of Practice Head within the BFSI space and later moved to IBM UK to spearhead their Business Development initiative again in the BFSI space. The tenure with IBM, with its strong processes & ability to structure large deals, exposed Niraj to the wider world of IT Services business. Niraj joined Usha Martin Technologies in the year 2013 and has played various roles in the organization and has been an integral part of the team that has revolutionized the concept of devotee engagement since then.</p>
                                    <p>Over the years, Niraj has been exposed to various geographies, businesses, people & cultures and has lived in the Netherlands, Belgium, Canada & the UK, and has spent considerable time in Malaysia, Thailand, Singapore. Niraj is currently based out of Kolkata, India.
                                    </p>
                                </div>
                                <div class="social">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div> -->
    </div>
</main>
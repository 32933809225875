<!-- menubar -->
<header class="navbar navbar-light navbar-expand-lg container-fluid py-0" id="header">
    <div class="container">
        <nav class="w-100 mt-2 d-flex justify-content-between navbar-container">
            <!-- <div class=""> -->
            <div class="col-sm-auto col-md-2">
                <a class="navbar-brand" href="#">
                    <img src="assets/images/logo.svg" class="img-fluid" alt="Shaktipeeth digital - logo" id="logo">
                </a>
            </div>

            <div class="col-auto p-0">
                <div class="d-flex justify-content-end align-items-center" *ngIf="isTabMobileView">

                    <div class="col-auto p-0">
                        <ul class="list-unstyled navbar-nav">
                            <!-- <li class="nav-item">
                            <button type="button" class="btn btn-clear bold position-relative p-0">
                                <span class="notification position-absolute"></span>
                                <span class="font-20 ic-">bag</span>
                            </button>
                        </li> -->
                            <li *ngIf="isLoggedOut$ | async" class="nav-item">
                                <button routerLink="login" type="button"
                                    class="btn btn-orange-light normal text-uppercase">
                                    {{ 'HEADER.LOGIN' | translate }}
                                </button>
                            </li>
                            <li *ngIf="isLoggedIn$ | async" class="nav-item dropdown profile">
                                <button class="btn btn-orange-light dropdown-toggle" type="button" id="profile"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="https://images.pexels.com/photos/1680172/pexels-photo-1680172.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                                        width="35" height="35" class="rounded-circle img" alt="...">
                                </button>
                                <div class="dropdown-menu dropdown-menu-right border-0 position-absolute" aria-labelledby="profile">
                                    <button routerLink="user/bookinghistory" type="button" class="dropdown-item bold">{{
                                        'HEADER.BOOKINGS' | translate }}</button>
                                    <!-- <button routerLink="user/shoopingcart" type="button" class="dropdown-item bold">
                                    <span class="active position-relative">
                                        <span class="notification position-absolute"></span>
                                        {{ 'HEADER.CART' | translate }}
                                    </span>

                                </button> -->
                                    <!-- <div class="col-12">
                                    <hr role="separator" class="my-1">
                                </div> -->
                                    <button routerLink="user/userprofile" type="button" class="dropdown-item">{{
                                        'HEADER.PROFILE' | translate }}</button>
                                    <button type="button" (click)="logout()" class="dropdown-item">{{
                                        'HEADER.LOGOUT' |
                                        translate }}</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="col-auto p-0">
                    <button class="navbar-toggler border-0 hello" type="button" aria-controls="navbar" aria-expanded="false"
                        aria-label="Toggle navigation" onclick="openNav();">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="col-12">
                        <hr role="separator" class="my-1">
                    </div>
                    <button routerLink="user/userprofile" type="button" class="dropdown-item">Profile</button>
                    <button type="button" (click)="logout()" class="dropdown-item">Logout</button>
                </div> -->

                    <!-- <button class="navbar-toggler border-0" type="button" aria-controls="navbar" aria-expanded="false"
                    data-toggle="offcanvas" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
                    <button class="navbar-toggler border-0" type="button" aria-controls="navbar" aria-expanded="false"
                        data-toggle="offcanvas" aria-label="Toggle navigation" onclick="openNav();">
                        <span class="navbar-toggler-icon"></span>
                    </button>
               
                </div>

                <div class="navbar-collapse offcanvas-collapse" id="navbar">
                    <ul class="navbar-nav mr-auto d-flex align-items-md-end align-items-lg-center">
                        <!-- <li class="nav-item" routerLinkActive="active">
                            <a routerLink="pooja" routerLinkActive="active" class="nav-link position-relative text-uppercase">{{ 'HEADER.POOJA' | translate }}</a>
                        </li> -->
                        <li class="nav-item" routerLinkActive="active" *ngFor="let list of headerList">
                            <a routerLink="{{list.url}}" routerLinkActive="active"
                                class="nav-link position-relative text-uppercase d-none d-md-block">{{ list.name | translate }}</a>
                            <a onclick="closeNav();" routerLink="{{list.url}}" routerLinkActive="active"
                                class="nav-link position-relative text-uppercase d-block d-md-none">{{ list.name | translate }}</a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active">
                            <a routerLink="astrology" routerLinkActive="active"  rou class="nav-link position-relative text-uppercase">astrology</a>
                        </li>
                        <li class="nav-item" routerLinkActive="active"> 
                            <a routerLink="ropeways" routerLinkActive="active" class="nav-link position-relative text-uppercase">ropeways</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link position-relative text-uppercase" href="#">about</a>
                        </li> -->
                        <li class="nav-item dropdown">
                            <button class="btn btn-clear bold dropdown-toggle change-lang text-uppercase"
                                id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{currentLang}}
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" *ngFor="let lang of language" [ngClass]="{'active':currentLang == lang.name }"
                                    routerLink="lang/{{lang.code}}">{{lang.name}}</a>
                            </div>
                            <!-- (click)="translate.use(lang.code)" -->
                        </li>
                        
                        <ng-container *ngIf="!isTabMobileView">
                            <li *ngIf="isLoggedIn$ | async" class="nav-item">
                                <button type="button" routerLink="user/userprofile/wallet"
                                    class="btn btn-clear bold position-relative wallet-info border-0">
                                    <span class="d-flex align-items-center">
                                        <span class="font-26 material-icons pr-2">account_balance_wallet</span>
                                        <em class="font-16 ic-">rupee</em><span class="balance">
                                            <ng-container *ngIf="authService.userWallet$ | async; let wallet">
                                                {{wallet.walletBalance || '0'}}</ng-container>
                                        </span>
                                    </span>
                                </button>
                            </li>

                            <li *ngIf="isLoggedOut$ | async" class="nav-item">
                                <button routerLink="login" type="button"
                                    class="btn btn-orange-light normal text-uppercase">
                                    {{ 'HEADER.LOGIN' | translate }}
                                </button>
                                <!-- <button type="button" class="dropdown-item bold">
                                Wallet
                            </button>
                            <div class="dropdown-menu dropdown-menu-right border-0" aria-labelledby="profile">
                                <button routerLink="user/bookinghistory" type="button" class="dropdown-item bold">
                                    {{ 'HEADER.BOOKINGS' | translate }}</button>
                                <button routerLink="user/shoopingcart" type="button" class="dropdown-item bold">
                                    <span class="active position-relative">
                                        <span class="notification position-absolute"></span>
                                        {{ 'HEADER.CART' | translate }}
                                    </span>

                                </button>
                                <div class="col-12">
                                    <hr role="separator" class="my-1">
                                </div>
                                <button routerLink="user/userprofile" type="button" class="dropdown-item">{{
                                    'HEADER.PROFILE' | translate }}</button>
                                <button type="button" (click)="logout()" class="dropdown-item">{{ 'HEADER.LOGOUT' |
                                    translate }}</button>
                            </div> -->
                            </li>
                            <li *ngIf="isLoggedIn$ | async" class="nav-item dropdown profile p-0">
                                <button class="btn btn-orange-light dropdown-toggle" type="button" id="profile"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img [src]="userProfile.profileImage || 'assets/images/user-avater.jpg'"
                                        onerror="this.onerror=null;this.src='assets/images/user-avater.jpg'" width="35"
                                        height="35" class="rounded-circle img" alt="...">
                                </button>
                                <div class="dropdown-menu dropdown-menu-right border-0" aria-labelledby="profile">
                                    <button routerLink="user/bookinghistory" type="button" class="dropdown-item bold">{{
                                        'HEADER.BOOKINGS' | translate }}</button>
                                    <!-- <button routerLink="user/shoopingcart" type="button" class="dropdown-item bold">
                                    <span class="active position-relative">
                                        <span class="notification position-absolute"></span>
                                        {{ 'HEADER.CART' | translate }}
                                    </span>
                                </button> -->
                                    <!-- <div class="col-12">
                                    <hr role="separator" class="my-1">
                                </div> -->
                                    <button routerLink="user/userprofile" type="button" class="dropdown-item">{{
                                        'HEADER.PROFILE' | translate }}</button>
                                    <button type="button" (click)="logout()" class="dropdown-item">{{
                                        'HEADER.LOGOUT' |
                                        translate }}</button>
                                </div>
                            </li>

                            <li *ngIf="isLoggedOut$ | async">
                                <div class="menuheader_udankhatola_logoDiv">
                                    <img src="assets/images/Udankhatola_logo.png" class="menu_udhankhatola_img" (click)="redirectToUdhanKhatola()">
                            
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <!-- </div> -->

        </nav>
    </div>
</header>

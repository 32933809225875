
import {HttpInterceptor, HttpRequest , HttpHandler, HttpEvent, HttpErrorResponse  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('#intercepted request ... ');
    const token = localStorage.getItem('accessToken');
    const languageParam = localStorage.getItem('langParam');
    let cloneReq = request;
    let params = request.params;
    if (languageParam && !params.get('language')) {
      params = params.append('language', languageParam);
    }
    if (token) {
      cloneReq = request.clone({
        params,
        headers: request.headers.set('Authorization', `Bearer ${token}`)
        // headers: request.headers.set('Authorization', `Basic cnpwX3Rlc3RfeUx6cXhXTHR0S2ZEU3U6NHd3SjlvNk0wUk9rUlpQRWRjUUQ3VUtn`)
      });
      // console.log(cloneReq)
    }else if (params){
      cloneReq = request.clone({params});
    }
    return next.handle(cloneReq)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('@Error: ', error, ' -- ', error.status);
        switch (error.status){
          case 500:
            // console.log('HTTP error occured.....');
            break;
          case 401:
            localStorage.removeItem('accessToken');
            // this._toastr.error("Your Session has expired, Please login again");
            window.top.location.href = '/login';
        }
        return throwError(error);
      })
    );
  }
}

<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 mt-5 text-center">
        <h1>Please wait..</h1>
        <h6>Do not refresh page</h6>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 mb-5 mt-2 text-center">
        <div class="clearfix">
          <img src="https://shaktipeethassetslive.s3.ap-south-1.amazonaws.com/images/home-page-banner-new-logo.png">
        </div>
      </div>
    </div>
    <!-- <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <button class="btn btn-clear" type="button" (click)="pay()">Pay</button>
      </div>
    </div> -->
  </div>
</div>

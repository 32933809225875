import { Component, OnInit } from '@angular/core';
// import { statusClass } from 'src/app/model/constants';
// import { ICarouselTopImages } from '../pooja/api/pooja.interface';
// import { AboutApiService } from '../about/api/about.api.service';

@Component({
  selector: 'app-ourservices',
  templateUrl: './ourservices.component.html',
  styleUrls: ['./ourservices.component.scss']
})
export class OurservicesComponent implements OnInit {

  constructor(

  ) { }



  ngOnInit(): void {
  }

 

}
